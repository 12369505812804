// @flow

import {map} from './Bus';


// UTILITIES

const Factory = {
  migration: fn => f => ({...f, ...fn(f)}),
  research: fn => Factory.migration(({research}) => ({research: fn(research)})),
  factors: fn => Factory.migration(({factors}) => ({factors: fn(factors)})),
  section: fn => Factory.migration(({section}) => ({section: fn(section)})),
};

const Section = {
  migration: fn => ({name, provides, parts}) => ({
    name,
    provides,
    ...fn({name, provides}),
    parts: parts.map(p => p.recipe ? p : Section.migration(fn)(p)),
  }),
  name: fn => Section.migration(({name}) => ({name: fn(name)})),
  provides: fn => Section.migration(({provides}) => ({provides: fn(provides)})),
};

const Provides = {
  migration: fn => provides => map(provides, (_, v) => fn(v)),
};

// TODO as needed
// const Block = {
// };


// MIGRATIONS

const migrateAddVersion = f => f;

const migrateProvidesValue =
  Factory.section(
    Section.provides(
      Provides.migration(v => v === "automatic" ? {} : {value: v})));

const migrateAddFactors = Factory.factors(f => f === undefined ?  [] : f);

const migrateDevHack = x => x;


const MIGRATIONS = [
  [undefined, "0.1", migrateAddVersion],
  ["0.1", "0.2", migrateProvidesValue],
  ["0.2", "0.3", migrateAddFactors],
  ["0.3", "0.3", migrateDevHack],
];

const migrate = (factory) => MIGRATIONS.reduce(
  (f, [prev, next, migration]) =>
    f.version === prev ? ({...migration(f), version: next}) : f,
  factory);

export default migrate;
