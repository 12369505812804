// @flow

import React, { useState, useContext } from 'react';

import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import Link from '@material-ui/core/Link';
import MenuItem from '@material-ui/core/MenuItem';
import Popover from '@material-ui/core/Popover';
import Select from '@material-ui/core/Select';
import Switch from '@material-ui/core/Switch';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import { useTheme, withStyles } from '@material-ui/core/styles';

import { bindTrigger, bindPopover } from 'material-ui-popup-state/hooks';

import { bus, width, map, scale, invert, filter, sort } from './Bus';
import { POLLUTION, CONSUMPTION } from './Game';
import { Duration, Game, Factors } from './Context';
import { Fixed, Rate, Icon } from './Basic';
import { Durations, NumericInput, Picker, usePopup, useToggle, POPOVER } from './Components';


const Block = ({products, byproducts, ingredients, required, crafter}) => {
  const
    px = useTheme().spacing.px,
    [replicated, toggleReplicated] = useToggle(true),
    visibility = replicated ? 'hidden' : 'visible',
    label = text =>
      <span style={{cursor: 'pointer'}}>
        <span>{text}</span>
        <span style={{margin: px(0, 0.5, 0, 1), visibility}}>
          {'\u2215'}
        </span>
        <IconText>
          <Icon item={crafter.building} style={{visibility}} />
        </IconText>
      </span>,
    items = is => replicated ? is : scale(is, 1.0 / (crafter.required || 1));

  return (
    <Box onClick={toggleReplicated}>
      <TooltipItemsRow label={label("Products")} items={items(products)} />
      <TooltipItemsRow label={label("Ingredients")} items={items(ingredients)} />
      <RequirementsItemsRow items={required} crafter={crafter} />
    </Box>
  );
};

const Section = ({level, provides, products, byproducts, ingredients, required, location}) => {
  const
    ps = width(provides) !== 0,
    add = <AddItem {...{level, location}} disabled={provides} />,
    fs = is => filter(is, (_, n) => Math.abs(n) > 1e-5);
  return <>
    <ProvidesItemsRow items={products} {...{provides, level, location}}>
      { ps ? add : [] }
    </ProvidesItemsRow>
    <TooltipItemsRow label={ps ? 'Byproducts' : 'Products'} items={fs(byproducts)}>
      { ps ? [] : add }
    </TooltipItemsRow>
    <TooltipItemsRow label="Ingredients" items={fs(ingredients)} />
    <RequirementsItemsRow items={required} />
  </>;
};

const Row = ({label, children}) =>
  React.Children.count(children) === 0 ? [] : (
    <Box>
      <Typography variant="h3">{label}</Typography>
      <Box clone={true}>
        <Typography variant="body1" component="div">{children}</Typography>
      </Box>
    </Box>
  );

const TooltipItemsRow = ({label, items, children=[]}) => {
  const game = useContext(Game);
  return (
    <Row label={label}>
      { sort(items, ...game.sort.items).map(
        ([id, n]) => <TooltipItem key={id} item={game.items[id]} amount={n} />)
      }
      { children }
    </Row>
  );
};

const ProvidesItemsRow = ({items, provides, level, location, children=[]}) => {
  const game = useContext(Game);
  return (
    <Row label="Products">{
      sort(items, ...game.sort.items).map(([id, n]) =>
        id === POLLUTION
          ? <TooltipItem key={id} item={game.items[id]} amount={n} />
          : <ProvidesItem
              key={id}
              item={game.items[id]}
              provides={provides[id]}
              required={level === 0}
              amount={n}
              location={location.extend(id)}
            />
      )
      }{
        children
      }
    </Row>
  );
};

const RequirementsItemsRow = ({items, crafter:{building, replication}={}}) => {
  const game = useContext(Game);
  return (
    <Row label="Requirements">{
      sort(items, ...game.sort.required).map(([id, n]) => {
        const r = id === building?.id ? replication : undefined;
        return (
          <RequirementsItem key={id} item={game.items[id]} amount={n} replication={r} />
        );
      })
    }</Row>
  );
};

const Tip = ({name, items, fixed}) => {
  return (
    <div>
      <Typography variant="h6" component="div">{name}</Typography>
      { items
        ? <Box mt={0.5}><Items {...{items, fixed}} variant="body2" /></Box>
        : [] }
    </div>
  );
};

const Items = ({items, fixed, variant='body1'}) => {
  const game = useContext(Game);
  return (
    <Typography variant={variant} component="span">{
      sort(items, ...game.sort.items).map(
        ([id, n]) =>
          <InlineItem key={id} item={game.items[id]} amount={n} fixed={fixed} />
      )
    }</Typography>
  );
};

const IconText = withStyles({
  root: {
    display: 'inline-block',
    // TODO: is this the right style name?  Will this work reliably?
    '.MuiLink-underlineHover:hover &': {
      // Using display: 'inline-block' prevents parent-element text decoration
      // (i.e., underlining) from applying to text in this (child) component.
      // We need to pass the text decoration style along via CSS.
      textDecoration: 'underline',
    },
  },
  icon: {
    display: 'inline-block',
    height: '16px',
    width: '16px',
    lineHeight: '16px',
    fontSize: '16px',
    verticalAlign: 'top',
    // Styles below set the padding required for correct vertical alignment for
    // different typography variants.
    '.MuiTypography-h5 &': {
      paddingTop: '2px',
      paddingBottom: '2px',
    },
    '.MuiTypography-body1 &': {
      paddingTop: '2px',
      paddingBottom: '2px',
    },
    '.MuiTypography-body2 &': {
      paddingTop: 0,
      paddingBottom: 0,
    },
  },
})(React.forwardRef(({classes, children, className, ...fwd}, ref) => {
  const [icon, ...text] = React.Children.toArray(children);
  return (
    // The Tooltip class sets props on its children, so for IconText to work
    // as a Tooltip trigger, we need to forward all additional props (`fwd`).
    <Box {...fwd} ref={ref} component="span" className={classes.root} mr={1}>
      <span className={classes.icon}>{icon}</span>
      <Box component="span" ml={0.5}>{text}</Box>
    </Box>
  );
}));

const InlineItem = React.forwardRef(({item, amount, fixed, duration, sign, units, ...fwd}, ref) => {
  const common = { amount, sign, units }; // appease syntax highlighting :(
  return (
    <IconText {...fwd} ref={ref}>
      <Icon item={item} />
      { fixed !== undefined
        ? <Fixed {...common} fixed={fixed} />
        : <Rate {...common} duration={duration} />
      }
    </IconText>
  );
});

const Item = withStyles({
  text: {
    alignSelf: "flex-end",
    marginTop: '-16px',
    padding: '0 2px',
    textShadow: '0 0 4px #000',
    background: 'linear-gradient(#CCC0, #444C)',
  },
})(React.forwardRef(
  ({item, amount, fixed, duration, sign, units, classes: {text}, ...fwd}, ref) => {
    const common = { amount, sign, units }; // appease syntax highlighting :(
    return (
      <Box {...fwd} display="inline-flex" flexDirection="column" p={0.5} ref={ref}>
        <Box p={0.5}><Icon item={item} size={32} /></Box>
        <Typography variant="caption" classes={{root: text}}>{
          fixed !== undefined
            ? <Fixed {...common} fixed={fixed} />
            : <Rate {...common} duration={duration} />
        }</Typography>
      </Box>
    );
  }
));

const TooltipItem = React.forwardRef(({item, amount, ...fwd}, ref) => {
  const game = useContext(Game);
  const items = (() => {
    if (item.type === "item") {
      const belts = bus(game.belts.map(({id, speed}) => [id, speed]));
      return scale(invert(belts), amount);
    } else if (item.type === "fluid") {
      // TODO: is it possible to be more data-driven for fluids?
      const pipes = {
        // Pipe throughput decreases with (uninterrupted) pipe length, so it's
        // impossible to accurately use a single value.  However, according to
        // the Factorio wiki, pipes shorter than 100 segments should be able to
        // maintain 1000 fluid per second.  A 100-segment long pipe could cross
        // 3 chunks and a 100-segment long pipe-to-ground could cross 14 chunks,
        // which seems longer than any practical piping need.  Thus, I believe
        // this represents a reasoanble lower bound on pipe throughput.
      'pipe': 1000,
      'pump': 6000, // assume pumping from a tank
      };
      return scale(invert(pipes), amount);
    } else {
      return null;
    }
  })();
  const title = <Tip name={item.name} items={items} fixed={2} />;
  return (
    <Tooltip title={title} {...fwd} ref={ref}>
      <Item {...{item, amount}} />
    </Tooltip>
  );
});

const ProvidesItem = ({item, provides, required, amount, location}) => {
  const [popup] = usePopup();
  return (
    <>
      <Link
        color="inherit"
        component="button"
        variant="body1"
        {...bindTrigger(popup)}>
        <TooltipItem {...{item, amount}} />
      </Link>
      <Popover {...bindPopover(popup)} {...POPOVER.CENTER}>
        <Box p={1.5}>
          <Provides {...{item, provides, required, amount, location}} />
        </Box>
      </Popover>
    </>
  );
};

const Provides = ({
  item,
  provides,
  required,
  amount,
  location,
}) => {
  const
    local = provides.value === undefined,
    [duration, setDuration] = useState(useContext(Duration)),
    [value, setValue] = useState(amount * duration),
    [factor, setFactor] = useState(provides.factor),
    toggle = () => location.set(local ? {value: value / duration, factor} : {});

  return <>
    <Typography variant="h5" component="div">{item.name}</Typography>
    <div>
      <Box mr={1} clone={true}>
        <Tooltip title={local ? "Set Production": "Automatic Production"}>
          <Switch
            size="small"
            color="primary"
            checked={!local}
            disabled={required}
            onChange={toggle}
            />
        </Tooltip>
      </Box>
      <NumericInput
        min={0}
        value={value}
        onValueChange={v => {
          setValue(v);
          location.extend('value').set(v / duration);
        }}
        disabled={local}
        style={{width: '8em'}}
        />
      { local ? null : <>
        <Box component="span" mx={1}>{'\u00D7'}</Box>
        <Factor
          value={factor === undefined ? -1 : factor}
          onChange={f => {
            setFactor(f);
            if (f === -1) {
              location.extend('factor').unset();
            } else {
              location.extend('factor').set(f);
            }
          }} />
        </>
      }
      <Box component="span" mx={1}>{'\u2215'}</Box>
      <Durations
        value={duration}
        onChange={d => {
          setValue(value / duration * d);
          setDuration(d);
        }} />
      <Box component="span" ml={3}>
        <Tooltip title="Remove Product">
          <IconButton size="small" onClick={() => location.unset()}>
            <DeleteForeverIcon />
          </IconButton>
        </Tooltip>
      </Box>
    </div>
  </>;
};

const Factor = ({value, onChange}) => {
  const factors = useContext(Factors);
  return (
    <Select value={value} onChange={e => onChange(e.target.value)}>
      <MenuItem key={0} value={-1}><Fixed amount={1.0} fixed={1} /></MenuItem>
      { factors.map(({name, value}, ii) =>
          <MenuItem key={ii} value={ii}>
            {name} (<Fixed amount={value} fixed={1} />)
          </MenuItem>
      )}
    </Select>
  );
};

const AddItem = withStyles(theme => ({
  icon: {
    fontSize: '32px',
    color: theme.palette.text.secondary,
    '&:hover': {
      color: theme.palette.text.primary,
    },
  },
}))(({level, disabled, location, classes: {icon}}) => (
  <Picker
    pick={Picker.ITEMS}
    disabled={map(disabled, (_, n) => !!n || n === 0)}
    onPick={p => location.extend(p.id).set(level === 0 ? {value: 0.0} : {})}>
    <Tooltip title="Add Product">
      <Link color="inherit" component="button" variant="body1">
        <Box p={1}><AddCircleOutlineIcon className={icon} /></Box>
      </Link>
    </Tooltip>
  </Picker>
));


const RequirementsItem = ({item, amount, replication}) => {
  // const game = useContext(Game);

  if (item.id === CONSUMPTION) {
    return (
      <Tooltip title={<Tip name={item.name} />}>
        <Item item={item} amount={amount} duration={1} precision={3} units="W" />
      </Tooltip>
    );

    // Electricity-generation game data is broken in v1.1+.  Disable for now
    // since it gave odd results for some reason anyway.
    /*
    const producers = bus(
      game.producers.map(({id, production}) => [id, production]));
    const required = map(scale(invert(producers), amount), (_, v) => Math.ceil(v));
    return (
      <Tooltip title={<Tip name={item.name} items={required} fixed={0} />}>
        <Item item={item} amount={amount} duration={1} precision={3} units="W" />
      </Tooltip>
    );
    //*/
  }

  const items = replication ? {[item.id]: replication} : undefined;
  return (
    <Tooltip title={<Tip name={item.name} items={items} fixed={3} />}>
      <Item item={item} amount={Math.ceil(amount)} fixed={0} />
    </Tooltip>
  );
};

/*
const Collapse = withStyles(theme => ({
  expanded: {
    flexGrow: 1,
  },
  collapsed: {
    flexGrow: 1,
    transition: theme.transitions.create(['background-color', 'color']),
    borderRadius: theme.spacing(0.5),
    '&:hover': {
      cursor: 'pointer',
      // To be consistent with button and input element hover states, `color`
      // shouldn't be set here and hoverOpacity should be used unchanged.
      // Consistent values, however, result in more jarring visual behavior
      // since the hover area is so much larger (5x+) than other buttons on the
      // page.  So the opacity is cut in half and the text color is made dimmer
      // to result in less brightness and contrast in the hover state.
      color: '#DDD',
      backgroundColor: theme.palette.addOpacity(
        theme.palette.action.hover, theme.palette.action.hoverOpacity * 0.5),
    },
  },
}))(({collapsed, products, ingredients, location, children, classes, ...fwd}) => {
  const open = collapsed ? () => location.toggle() : () => {};
  const content = collapsed ? classes.collapsed : classes.expanded;
  const [controls, header, details] = React.Children.toArray(children);
  return (
    <Box display="flex" {...fwd} width={useTheme().spacing(100)}>
      { controls }
      <Box ml={1} onClick={open} className={content}>
        { header }
        { collapsed
          ? <Summary products={products} ingredients={ingredients} />
          : details
        }
      </Box>
    </Box>
  );
});

const Label = props =>
  <Box pr={1} clone={true}>
    <Typography variant="h5" component="span" {...props}/>
  </Box>;

*/


export { Block, Section, Item };
