// @flow

import React from 'react';

import { EMPTY } from './Game';


const Duration = React.createContext(60);
const Game = React.createContext(EMPTY);
const Factors = React.createContext([]);


export { Duration, Game, Factors };
