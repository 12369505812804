// @flow

const STORAGE = window.localStorage;

const available = (storage => {
  try {
    if (!storage) {
      return false;
    }
    const TEST = '__LOCAL_STORAGE_TEST__';
    storage.setItem(TEST, TEST);
    storage.removeItem(TEST);
    return true;
  } catch(e) {
    return (
      e instanceof DOMException
      && (e.code === 22
          || e.code === 1014
          || e.name === 'QuotaExceededError'
          || e.name === 'NS_ERROR_DOM_QUOTA_REACHED')
      && window.localStorage.length !== 0
    );
  }
})(STORAGE);

function set(k, v) {
  if (!available) {
    return false;
  }

  STORAGE.setItem(k, v);
  return true;
}

function get(k) {
  if (!available) {
    return;
  }
  return STORAGE[k];
}

function remove(k) {
  if (!available) {
    return;
  }
  STORAGE.removeItem(k);
}

export default { get, set, remove };
