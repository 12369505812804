// @flow

import S3 from 'aws-sdk/clients/s3';


const AWS_S3 = new S3({
  apiVersion: '2006-03-01',
  region: 'us-west-1',
  // user: jsfc-client
  accessKeyId: 'AKIAJ2P2BHZ7HYQMCFUA',
  secretAccessKey: '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',
});

function get(bucket, id) {
  return AWS_S3.getObject({ Bucket: 'jsfc-' + bucket, Key: id }).promise()
    .then(res => JSON.parse(new TextDecoder('utf-8').decode(res.Body)));
}

function put(bucket, id, string) {
  return AWS_S3.putObject({
    Bucket: 'jsfc-' + bucket,
    Key: id,
    ContentType: 'application/json',
    Body: JSON.stringify(string), // TODO: gzip?
  }).promise();
}


export default { get, put };
