// @flow

import invariant from 'invariant';
import React, { useContext } from 'react';

import { Duration } from './Context';

const format = Number => ({sign=false, units='', amount, ...fwd}) => {
  const s = (sign && amount > 0 && '+') || (sign && amount < 0 && '-') || '';
  return <>{s}<Number amount={amount} {...fwd} />{units}</>;
};

const Rate = format(({amount, duration, precision=3}) => {
  const SI = ['n', 'µ', 'm', '', 'k', 'M', 'G'], E = -3;
  const duration_context = useContext(Duration);
  const number = Math.abs(amount) * (duration || duration_context);
  if (number === 0) {
    return '0.0';
  }
  for (let e = E; e - E < SI.length; e++) {
    if (number < Math.pow(1000, e + 1)) {
      return (number / Math.pow(1000, e)).toPrecision(precision) + SI[e - E];
    }
  }
  return invariant(false, 'Unknown Number Range: ' + number);
});

const Fixed = format(({amount, fixed}) =>
  Number(amount).toLocaleString(undefined, {
    minimumFractionDigits: fixed,
    maximumFractionDigits: fixed,
  })
);

const Delta = ({amount, base=1}) =>
  <Fixed amount={100 * (amount - base) / base} fixed={0} sign={true} units="%" />;

const Icon = ({item: {icon, name}, size, style, className}) =>
  <img
    src={icon}
    alt={name}
    height={size || 16}
    width={size || 16}
    style={style}
    className={className}
  />;

const tap = (f, ...ts) =>
  (...args) => { ts.forEach(t => t(...args)); return f(...args); };


export { Rate, Fixed, Delta, Icon, tap };
