// @flow

import update from 'immutability-helper';

class Location {
  static forState(state, setState, setFocus) {
    return new Location(succ => setState(state = succ(state)), setFocus);
  }

  constructor(updater, focuser, location = []) {
    this.updater = updater;
    this.focuser = focuser;
    this.location = location;
  }

  extend(...extensions) {
    return new Location(this.updater, this.focuser, [...this.location, ...extensions]);
  }

  resolve(command, value) {
    function x(r, l) { return {[l]: r}; }
    return [...this.location].reverse().reduce(x, {[command]: value});
  }

  update(command, value) {
    this.updater(prev => update(prev, this.resolve(command, value)));
  }

  pop(command, value) {
    const loc = [...this.location], pop = loc.pop();
    (new Location(this.updater, this.focuser, loc)).update(command, value(pop));
  }

  set(value) {
    this.update('$set', value);
  }

  toggle() {
    this.pop('$toggle', p => [p]);
  }

  unset() { // remove from object
    this.pop('$unset', p => [p]);
  }

  push(...push) {
    this.update('$push', push);
  }

  splice(...splice) {
    this.update('$splice', [splice]);
  }

  remove() { // remove from array
    this.pop('$splice', p => [[p, 1]]);
  }

  transpose() { // swap current and next element in array
    this.pop('$apply', p => ts => [...ts.slice(0, p), ts[p + 1], ts[p], ...ts.slice(p + 2)]);
  }

  duplicate(fn=i => i) {
    this.pop('$apply', p => ts => [...ts.slice(0, p + 1), fn(ts[p]), ...ts.slice(p + 1)]);
  }

  focus(parents=0) {
    const location = this.location.slice(2).filter((_, ii) => ii % 2 === 1);
    this.focuser(location.slice(0, location.length - parents));
  }

  static equal(lhs, rhs) {
    return lhs.location.every((l, n) => rhs.location[n] === l)
      && rhs.location.every((l, n) => lhs.location[n] === l);
  }
}

export default Location;
