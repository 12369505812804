// @flow

const History = {

  NS: { SHARES: 'shares', SAVES: 'saves', GAMES: 'games' },

  set: (ns, id) => {
    if (window.location.pathname !== '/' + id) {
      const method = window.location.pathname === '/' ? 'replaceState' : 'pushState';
      window.history[method](
        { ns, id },
        '', // Unused, apparently
        window.location.origin + '/' + ns + '/' + id,
      );
    }
  },

  hash: (hash) => {
    var location = new URL(window.location);
    location.hash = hash;
    window.history.replaceState(window.history.state, '', location);
  },

};

export default History;
