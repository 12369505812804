// @flow

import S3 from './S3';

import GAMES from './GAMES';


const EMPTY = {
  version: '0.1',
  name: '',
  mods: {},
  groups: [],
  subgroups: {},
  items: {},
  recipes: {},
  crafters: {},
  beacons: {}, // a root!?!
  modules: {},
  effects: [],
  fuels: {},
  belts: [],
  producers: [],
  technologies: [],
};

const POLLUTION = "jsfc-pollution";
const CONSUMPTION = "jsfc-consumption";

function reference({groups, subgroups, items, recipes, crafters, beacons, modules, fuels}) {
  groups.forEach(g => {
    g.subgroups = g.subgroups.map(s => subgroups[s]);
  });
  Object.values(subgroups).forEach(s => {
    s.items = s.items.map(i => items[i]);
    s.recipes = s.recipes.map(r => recipes[r]);
  });
  Object.values(recipes).forEach(r => {
    r.crafters = r.crafters.map(c => crafters[c]);
    r.modules = r.modules.map(m => modules[m]);
  });
  Object.values(crafters).forEach(c => {
    c.modules = c.modules.map(m => modules[m]);
    c.fuels && (c.fuels = c.fuels.map(f => fuels[f]));
  });
  Object.values(beacons).forEach(b => {
    b.modules = b.modules.map(m => modules[m]);
    b.fuels && (b.fuels = b.fuels.map(f => fuels[f]));
  });
}

function sorter(game) {
  game.sort = {
    items: [
      (id, _) => game.items[id].type === "item" ? 0 : 1, // first items
      (id, _) => game.items[id].type === "fluid" ? 0 : 1, // then fluids
      (_, n) => -n, // most to least
    ],
    required: [
      (id, _) => game.crafters[id] ? 0 : 1, // first crafters
      (id, _) => game.beacons[id] ? 0 : 1, // then beacons
      (id, _) => game.modules[id] ? 0 : 1, // then modules
      (_, n) => -n, // most to least
    ],
  };
}

function iconize(game, images) {
  const PREFIX = 'data:image/png;base64,';
  const MISSING = 'iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAABlElEQVR4AcWWQUoDQRREq353QkQXeoScxI27iLgTL+ApBFE8hTdwJxJ3blx4DsG1CyGCASfz/TN0g8yEaRf55EOlSaahHpXqTrDtYVrj0eXjQ6WcrWoF1M8tCBGpT8+3J6cAqpge7VQ1Z/dXx/ip8t7NjqZ1FIGz6/ms8TQtMsBkpdqa3728IZAuBI3HxeG0XRvPvwDMiOMoiCRIbtZfFZUpw+SQIzoThSYBiQ0DEKhr9Py6MYUgCKQDgInSK3g/AdIHAOsndjcFYSuXU1D3QNZ3QJwAOJyA5gRcATS9GyghIXQCIMolDOIIkM2HT4HA6SKEgP87BV4AqiYUvwLxBCgnEAMdfw0HEtD0Eh2OYNdDSxeR55RLSF+Acgn9EygABDr+JywA5BKqD0D5KtZ0D6gTAcmBDqQnIW/yAcheAxeRELUTgQwlQBJCYBTFANQLwNR69QGCEJNx+5FjAmg9gqwBGAV8nd/M91a1upawMdfl52tuQkbZN01NBybCdyrTh+ndtGAOwLRrGsN/1LQ0fSeY7c4vc4WHKmWEUZYAAAAASUVORK5CYII=';
  const resolve = i => PREFIX + (images[i] || MISSING);
  ['groups', 'belts', 'producers']
    .forEach(ts => game[ts].forEach(t => t.icon = resolve(t.icon)));
  ['items', 'recipes', 'crafters', 'beacons', 'modules', 'fuels']
    .forEach(ts => Object.values(game[ts]).forEach(t => t.icon = resolve(t.icon)));
  game.technologies.map(t => t.icons = t.icons.map(resolve));
  delete game.images;
}

function localize(game) {
  // TODO: Proper Internationalization and Localization
  const resolve = id => id
    .replace(/^jsfc-/, '')
    .replace(/-/g, ' ')
    .split(' ')
    .map(w => w[0].toUpperCase() + w.slice(1))
    .join(' ');

  ['groups', 'belts', 'technologies']
    .forEach(ts => game[ts].forEach(t => t.name = resolve(t.id)));
  ['items', 'recipes', 'crafters', 'beacons', 'modules', 'fuels']
    .forEach(ts => Object.values(game[ts]).forEach(t => t.name = resolve(t.id)));
}

function load(id) {
  return S3.get('games', id).then(game =>
    Promise.all(game.images.map(id => S3.get('images', id))).then(iss => {
      iconize(game, iss.reduce((a, v) => ({...a, ...v}), {}));
      localize(game); // TODO
      reference(game);
      sorter(game);
      delete game.mods.JSFC;
      return { ...game, id }; // TODO: include id in data?  not return id?
    })
  );
}

const Game = { load, GAMES, EMPTY, POLLUTION, CONSUMPTION };


export { Game as default, GAMES, EMPTY, POLLUTION, CONSUMPTION };
