// @flow

import React, { useRef, useState } from 'react';

import { useTheme } from '@material-ui/core/styles';


const Wordmark = ({size}) => {
  const L = size / 2;
  const G = 2 * Math.ceil(Math.log2(size) - 3);
  const xl = -L * Math.sqrt(3) / 2;
  const xr = 5.57 * L;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={`${xr - xl}px`}
      height={`${size}px`}
      viewBox={[xl, -L, xr - xl, 2 * L].join(' ')}
      lang="en">
      <Icon {...{L, G}} />
      <text
        x={1.2 * L}
        y={0.55 * L}
        fill={useTheme().palette.secondary.main}
        fontFamily="Noto Sans"
        fontStyle="italic"
        fontWeight={700}
        fontSize={`${size * 1.0625}px`}>
        JSFC
      </text>
      <text
        x={1.8 * L}
        y={0.92 * L}
        fill={useTheme().typography.h1.color}
        fontFamily="Noto Sans"
        fontStyle="italic"
        fontWeight={700}
        fontSize={`${size * 0.1875}px`}>
        BY JEFFREY SPEHAR
      </text>
    </svg>
  );
};

const Logo = React.forwardRef(({size}, ref) => {
  const L = size / 2;
  const G = 2 * Math.ceil(Math.log2(size) - 3);
  return (
    <svg
      ref={ref}
      xmlns="http://www.w3.org/2000/svg"
      width={`${size}px`}
      height={`${size}px`}
      viewBox={[-L, -L, 2 * L, 2 * L].join(' ')}>
      <Icon {...{L, G}} />
    </svg>
  );
});

const P = (() => {
  const points = ps => ps.map(([x, y]) => x + ',' + y).join(' ');
  return ({ps, f, ...fwd}) =>
    <polygon
      points={points(ps)}
      stroke={useTheme().palette.primary.main}
      fill={f ? useTheme().palette.primary.main : "none"}
      {...fwd}
    />;
})();

const [hexagon, diamond] = (() => {
  const D = Math.sqrt(3) / 2;
  return [
    [[0, 1], [D, 0.5], [D, -0.5], [0, -1], [-D, -0.5], [-D, 0.5]],
    [[0, 0], [0, 1], [D, 0.5], [D, -0.5]],
  ];
})();

const ts = (ps, [tx=1, ty], [dx, dy]=[0,0]) => ps.map(
  ([x, y]) => [x * tx + dx, y * (ty || tx) + dy]);

const sub = ([xl, yl], [xr, yr]) => [xl - xr, yl - yr];
const step = ([dx, dy], G, i) => [i / G * dx, i / G * dy];

const Icon = ({L, G}) => {
  const
    H = ts(hexagon, [L]),
    H2 = ts(H, [1 / 2]),
    D = ts(diamond, [L]),
    sw = {strokeWidth: L / (G + 1) / 5},
    mask = {fill: '#FFFFFF', strokeWidth: 0},
    sc = L / (L + sw.strokeWidth / Math.sqrt(3));

  return <g transform={`scale(${sc}, ${-sc})`}>
    <mask id={`inner-${L}`}><P ps={H2} {...mask} /></mask>
    <g mask={`url(#inner-${L})`}>
      {Array.from(Array(G / 2 + 1).keys()).map(i => {
        const ps = ts(D, [0.5, -0.5], step(sub(H2[4], H2[3]), G / 2, i));
        return <P key={`inner-${i}`} ps={ps} {...sw} />;
      })}
      <P ps={[H2[4], H2[1], H2[2], H2[5]]} {...sw} f={true} />
    </g>
    <P ps={H2} {...sw} />
    <mask id={`outer-${L}`}><P ps={H} {...mask} /></mask>
    <g mask={`url(#outer-${L})`}>
      {Array.from(Array(G + 1).keys()).map(i => {
        const ps = ts(D, [], step(sub(H[5], H[0]), G, i));
        return <P key={`outer-${i}`} ps={ps} {...sw} />;
      })}
    </g>
    <P ps={H} {...sw} />
  </g>;
};

const Export = ({size}) => {
  const background = useTheme().palette.background.paper;
  const ref = useRef(null);
  const logo = <Logo size={size} ref={ref} />;
  const [image, setImage] = useState();
  const onClick = () => {
    if (!ref.current) {
      console.warning('No Logo Ref Available');
      return;
    }

    const svg = new Image();
    svg.onload = () => {
      const canvas = document.createElement('canvas');
      canvas.width = canvas.height = size;
      canvas.getContext('2d').fillStyle = background;
      canvas.getContext('2d').fillRect(0, 0, size, size); // TODO: hexagon?
      canvas.getContext('2d').drawImage(svg, 0, 0, size, size);

      setImage(
        <img src={canvas.toDataURL('image/png')} alt="Export" />
      );
    };
    svg.src = `data:image/svg+xml;base64,${btoa(ref.current.outerHTML)}`;
  };
  const button = ref.current ? [] : <button onClick={onClick}>Export</button>;
  return <div>{logo}{button}{image}</div>;
};

/* LOGO EXPORT:
  return (
    <Theme>
      <div>PNG</div>
      <Export size={512} />
      <Export size={192} />
      <div>For ICO</div>
      <Export size={256} />
      <Export size={128} />
      <Export size={64} />
      <Export size={32} />
      <Export size={16} />
    </Theme>
  );
*/


export { Logo, Wordmark, Export };
