// @flow

/**
 * Returns a random 128-bit integer encoded in base64 (url) format.  The
 * likelihood of ever generating the same number twice is small enough to be
 * treated as essentially zero, which makes this a reasonable generator of
 * globally unique IDs.
 **/
function id() {
  let a = new Uint8Array(16);
  window.crypto.getRandomValues(a);
  return btoa(a.reduce((s,v) => s + String.fromCharCode(v), ''))
    .substr(0, 22).replace(/\+/g, '-').replace(/\//g, '_');
}

export default id;
