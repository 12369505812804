// @flow

import { scale, merge, map, filter, width } from './Bus';
import { POLLUTION, CONSUMPTION } from './Game';


function block({id, recipe, crafter, beacon}, net, research_effects) {
  const
    effects = (() => {
      const effects = [
        ...crafter.modules.slice(0, crafter.building.slots).map(m => m.effects),
        ...(
          beacon.building && crafter.building.slots > 0
            ? beacon.modules.slice(0, beacon.building.slots)
              .map(m => scale(
                m.effects,
                beacon.building.transmission * beacon.replication))
            : []
        ),
        recipe.effects,
        research_effects[crafter.building.id] || {},
        { speed: 1, productivity: 1, consumption: 1, pollution: 1 },
      ].reduce(merge, {});
      map(effects, (k, v) => k === "consumption" ? Math.max(0.20, v) : v);
      return effects;
    })(),
    duration = recipe.duration / crafter.building.speed / effects.speed,
    replication = Object.entries(recipe.items)
      .filter(([item, _]) => net[item])
      .map(([item, number]) =>
         -net[item] * duration / number / effects.productivity)
      .reduce((a, v) => Math.max(a, v), 0),

    crafter_ = {
      building: crafter.building,
      modules: crafter.modules,
      replication,
      geometry: (() => {
        const
          machines = Math.ceil(replication),
          rows = Math.min(machines, crafter.geometry.rows),
          columns = rows === 0 ? 0 : Math.ceil(machines / rows),
          blocks = 1;
        return { machines, rows, columns, blocks };
      })(),
      required: Math.ceil(replication),
      fuel: crafter.fuel,
    },
    beacon_ = {
      ...beacon, // building, modules, replication, geometry, fuel
      required: ((c, b) => Math.ceil(
          c.machines * b.machines
        + c.rows     * b.rows
        + c.columns  * b.columns
        + c.blocks   * b.blocks
      ))(crafter_.geometry, beacon.geometry),
    },

    beacons = beacon_.building && beacon_.modules.length > 0 && beacon_.replication > 0,
    energy = {
      crafter: crafter_.required * crafter_.building.energy.baseline
        + crafter_.replication * crafter_.building.energy.crafting * effects.consumption,
      beacon: beacons ? beacon_.required * beacon_.building.energy.baseline : 0,
    },
    pollution =
      crafter_.replication
      * crafter_.building.energy.crafting
      * crafter_.building.pollution
      * effects.consumption
      * effects.pollution
      + energy.beacon * beacon.building.pollution,
    balance = {
      products: {
        ...scale(
          filter(recipe.items, (_, n) => n > 0),
          replication / duration * effects.productivity),
        ...(pollution > 0 ? { [POLLUTION]: pollution } : {}),
      },
      byproducts: {},
      ingredients: [
        scale(filter(recipe.items, (_, n) => n < 0), - replication / duration),
        (crafter_.building.fuels && crafter_.fuel
          ? { [crafter_.fuel.id]: energy.crafter / crafter_.fuel.energy }
          : {}),
        (beacon_.building.fuels && beacon_.fuel
          ? { [beacon_.fuel.id]: energy.beacon / beacon_.fuel.energy }
          : {}),
      ].reduce(merge, {}),
      required: [
        ...crafter_.modules.slice(0, crafter_.building.slots)
          .map(({id}) => ({[id]: crafter_.required})),
        ...(beacons
            ? beacon_.modules.slice(0, beacon_.building.slots)
              .map(({id}) => ({[id]: beacon_.required}))
            : []),
        { [crafter_.building.id]: crafter_.required },
        crafter_.building.fuels || energy.crafter === 0
          ? {}
          : { [CONSUMPTION]: energy.crafter },
        beacons ? { [beacon_.building.id]: beacon_.required } : {},
        !beacons || beacon_.building.fuels || energy.beacon === 0
          ? {}
          : { [CONSUMPTION]: energy.beacon },
      ].reduce(merge, {}),
      effects,
    };

  return [
    { id, recipe, crafter: crafter_, beacon: beacon_, balance },
    merge(balance.products, scale(balance.ingredients, -1)),
  ];
}

function section(part, net, effects, factors) {
  let target = {};
  for (const [item, num] of Object.entries(part.provides)) {
    target[item] = num.value
      ? -num.value * (num.factor === undefined ? 1 : factors[num.factor].value)
      : (net[item] || 0);
  }

  let products = {};
  for (const [item, num] of Object.entries(target)) {
    products[item] = Math.max(0, -num);
  }

  let parts = [], delta = {}, required = {};
  for (const p of part.parts) {
    const [b, d] = p.recipe
      ? block(p, target, effects)
      : section(p, target, effects, factors);
    parts.push(b);
    target = merge(target, d);
    delta = merge(delta, d);
    required = merge(required, b.balance.required);
  }

  let ingredients = {}, byproducts = {};
  const diff = merge(delta, scale(products, -1));
  for (const [item, number] of Object.entries(diff)) {
    if (number < 0) {
      ingredients[item] = -number;
    } else if (number > 0) {
      byproducts[item] = number;
    }
  }

  // Pollution is never part of the 'target' proucts and so balance() will treat
  // it as a byproduct.  However, since it's essentially expected as a game
  // mechanic for all recipes, it makes more sense to list it as a product if
  // it's the only byproduct for a section or block.
  // TODO: Should this live in the display layer?
  if (byproducts[POLLUTION] && width(byproducts) === 1) {
    products[POLLUTION] = byproducts[POLLUTION];
    delete byproducts[POLLUTION];
  }

  return [
    {
      ...part, // id, name, parts, provides
      parts, // override `parts` with balanced instances
      balance: { products, byproducts, ingredients, required },
    },
    delta,
  ];
}

function technologies(technologies, research) {
  return technologies.map(({id, name, infinite, effects, icons}) => {
    const
      level = research[id],
      last = effects.length - 1,
      max = infinite ? undefined : last,
      icon = icons[level] || icons[icons.length - 1],
      calc = () => {
        const t = {};
        const m = (c, es) => { t[c] = merge(t[c] || {}, es); };
        map(effects[last], m);
        map(map(infinite, (_, es) => scale(es, level - last)), m);
        return t;
      };
    return { id, name, level, max, effects: effects[level] || calc(), icon };
  });
}

function balance(factory) {
  const research = technologies(factory.game.technologies, factory.research);
  const effects = research.map(({effects}) => effects).reduce(
    (acc, effects) => {
      Object.entries(effects).forEach(
        ([c, es]) => acc[c] = merge(acc[c] || {}, es));
      return acc;
    }, {});
  return {
    game: factory.game,
    research: research,
    factors: factory.factors,
    section: section(factory.section, {}, effects, factory.factors)[0]
  };
}


export default balance;
