// @flow

import React, { useState, useEffect } from 'react';

import Location from './Location';
import Theme from './Theme';
import { Duration, Game, Factors } from './Context';
import { Picker } from './Components';
import Navigation from './Navigation';
import Columns from './Columns';
import balance from './balance';

/*
TODO:

* Bugs
  * Picker - selected tab underline is junk on first open

* Logistics
  * Science usage as recipes in labs
  * Handle rocket-silo "Required" correctly (pass separate state data in balance
    to sum the required silos correctly across bloks/recipes?)
  * Inserter Planning?
  * Electricity generation recipes?
  * Train and Transportation Planning?
  * Solver for oil? (and other feedback loops?) - e.g., electricity?

* UI
  * Upgrade to MUI v5.  (Styling system looks a lot more sensible...)
  * Better "disabled" state in picker - grey overlay or something?  Current
    state is just no hover; impossible to tell from just looking.
  * Beacon Replication Number is too low in the numeric output - maybe this was
    tuned for the geometry case?  Should be visually centered in this case.
    In addition to the text, the spinner buttons look a bit too small?
  * Graphics
    * ICO looks pretty bad in the browser.  Maybe just a hexagon for 16px?
      at other low-res icons too to see if they're OK...)
    * Move wordkmark to images?  (and remove Noto Sans font loading...)
    * Loader - use logo (embedded image?)
    * Replace name with line in wordmark?
  * Required Item loses state - duration resets on collapse.  JSFC would need to
    preserve the components on collapse - maybe just hide instead of not render?
    That would mean rendering components that aren't shown, and then collapsing
    wouldn't benefit performance.  Improved performance may be a prerequisite.
  * Try: make hover the light yellow color?  (could also try orange/green?)
  * New Section looks pretty weird - just a couple of unaligned (+) buttons.
    Maybe always show the Add buttons if parts is empty?
  * Add button group 100% width?
  * Bug: Cannot set Production to 0.
  * Bug: Deleting an item from the Products list causes a crash.
  * Navigating "across" in the tree hierarchy feels weird on a narrow screen -
    since things move left/right, opening a parent branch of the hierarchy
    can cause things to move / shift in a really confusing way.  Hard to tell
    what went where.  Maybe animation would help?  But we probably just need
    different behavior for these types of "uncle/aunt" navigations.
  * We also need a big, easy way to go "back".  Maybe a top-to-bottom button on
    the left edge of the screen?

* Persistence / State Management
  * Back button (history navigation) doesn't work
  * Cross-browser sharing doesn't work - local storage saves a stale copy, and
    there's not a way to force a server sync.  Create game in browser A, edit,
    and save.  Load game in browser B, edit, save.  Reload in A - none of B's
    changes appear since data is pulled from local storage.  Need to hit network
    on load?  (behavior should be: no object - ignore.  object exists - use that
    (and update local storage) - does this work?)
  * Error handling - what happens if a fetch fails?  How can we tell "does not
    exist" apart from "network failed"?  This will be required for proper state
    management?
  * TODO: CRDT?  This would simplify a lot of the state/error management, and
    sharing.  We could remove "saving" as a concept?  Server might need to
    be more complicated though.
  * Maybe get rid of local storage altogether for now?  Seems... very fragile.

* Mobile
  * Touch devices - remove/replace hover states
  * Handle narrower widths

* Internationalization

* Deployment and Launch
  * Help / Docs
  * Hooks to validate bucket data
  * Separate dev/test from production data
  * ToS / PP

* Game Data
  * 64px Icon for Rocket Launch
  * Handle mipmaps correctly - barreling icons are incorrect.  Scaling /
    shifting appears incorrect (maybe the cropping too?)  May need to read the
    mipmap data from somewhere?
  * Improved Barrel Icons - accurate to Game coloring/tinting
  * Uranium Ore shows as mineable with a burner drill, however this is not
    possible in the game because uranium ore requires a fluid (sulfuric acid) to
    mine.  However, I can't seem to see a way to get this info programatically.
    The "resource_categories" array would be a natural way to do this, but
    unfortunately all ores are simply "basic-solid" vs. using a fluid-specific
    category (e.g., as is done with crafting).  How does this work?  Maybe the
    building itself (burner drill vs. electric drill) contains some info?  The
    resource perhaps has some info too?
  * Possible to use `module.limitations` API to determine whether productivity
    modules apply?  (Saw this code in another mod: https://mods.factorio.com/mod/extended-descriptions)
  * Unzip in memory.  Unclear whether a malicious mod could unzip itself into
    arbitrary locations on disk.

* Performance
  * balance() takes ~50ms initially and ~20ms subsequently on a full-sized
    factory.  (This is with profiling; likely faster in production.)
    * What does this imply for a gradient-descent solver?

* Code
  * Test with Game.EMPTY?
*/

const useLocation = (init, setFocus) => {
  const [state, setState] = useState(init);
  return [state, Location.forState(state, setState, setFocus)];
};

const App = ({initialState, onDurationChange, onFocusChange, onStateChange}) => {
  const [duration, setDuration] = useState(60);
  const [focus, setFocus] = useState([]);
  const [{id, factory}, location] = useLocation(initialState, setFocus);
  const picker = useState(Picker.state(factory.game));

  useEffect(() => { onDurationChange(duration); });
  useEffect(() => { onFocusChange(focus); });
  useEffect(() => { onStateChange({id, factory}); });

  const {game, research, section} = balance(factory);

  return (
    <Theme>
      <Duration.Provider value={duration}>
        <Navigation {...{id, factory, research, location, setDuration}} />
        <Game.Provider value={game}>
          <Factors.Provider value={factory.factors} >
            <Picker.Context.Provider value={picker}>
              <Columns {...{section, focus, location}} />
            </Picker.Context.Provider>
          </Factors.Provider>
        </Game.Provider>
      </Duration.Provider>
    </Theme>
  );
};

export default App;
