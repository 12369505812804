// @flow

import invariant from 'invariant';

import React, { useContext, useState } from 'react';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Popover from '@material-ui/core/Popover';
import Select from '@material-ui/core/Select';
import Tab from '@material-ui/core/Tab';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import TabContext from '@material-ui/lab/TabContext';
import TabList from '@material-ui/lab/TabList';
import TabPanel from '@material-ui/lab/TabPanel';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import DeleteIcon from '@material-ui/icons/Delete';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import UnfoldLessIcon from '@material-ui/icons/UnfoldLess';
import UnfoldMoreIcon from '@material-ui/icons/UnfoldMore';
import { withStyles } from '@material-ui/core/styles';

import { usePopupState, bindTrigger, bindPopover, bindMenu } from 'material-ui-popup-state/hooks';

import { Duration, Game } from './Context';
import { Icon, Delta, tap } from './Basic';


const DURATIONS = [ // in seconds
  [1, '1s'],
  [10, '10s'],
  [60, '1m'],
  [10 * 60, '10m'],
  [60 * 60, '1h'],
  [10 * 60 * 60, '10h'],
  [50 * 60 * 60, '50h'],
];

const POPOVER = {
  LEFT: {
    anchorOrigin: {vertical: 'bottom', horizontal: 'left'},
    transformOrigin: {vertical: 'top', horizontal: 'left'},
  },
  CENTER: {
    anchorOrigin: {vertical: 'bottom', horizontal: 'center'},
    transformOrigin: {vertical: 'top', horizontal: 'center'},
  },
  RIGHT: {
    anchorOrigin: {vertical: 'bottom', horizontal: 'right'},
    transformOrigin: {vertical: 'top', horizontal: 'right'},
  }
};

const MENU = {
  LEFT: {...POPOVER.LEFT, getContentAnchorEl: null},
  CENTER: {...POPOVER.CENTER, getContentAnchorEl: null},
  RIGHT: {...POPOVER.RIGHT, getContentAnchorEl: null},
};

const NumericInput = withStyles({
  root: {
    width: '5em',
  },
})(React.forwardRef(({onValueChange=()=>{}, min, max, step, ...fwd}, ref) => {
  const onChange = ({target: {value}}) =>
    onValueChange(value ? Number(value) : undefined);
  return (
    <TextField
      {...fwd}
      {...{onChange, ref}}
      inputProps={{min, max, step}}
      type="number" />
  );
}));
NumericInput.muiName = TextField.muiName;

const Durations = ({ value, onChange }) => {
  const duration = useContext(Duration);
  return (
    <Select
      style={{width: '3.6rem'}}
      value={value || duration}
      onChange={event => onChange(event.target.value)}
      MenuProps={MENU.LEFT}>{
      DURATIONS.map(
        ([time, label]) =>
          <MenuItem key={time} value={time}>{label}</MenuItem>)
    }</Select>
  );
};

// TODO : Use MUI Grid layout component?
const Picker = withStyles(theme => ({
  box: {
    width: '640px',
  },
  tab: {
    padding: theme.spacing.px(1, 3),
    minWidth: 0,
  }
}))(({pick, onPick=()=>{}, onClose=()=>{}, disabled={}, children, classes}) => {
  // pick: "items" | "recipes"
  const
    groups = useContext(Game).groups,
    rows = Math.max(...groups.map(g =>
      g.subgroups
        .map(sg => Math.ceil(sg[pick].length / 10))
        .reduce((r, e) => r + e, 0)
    )),
    [popup, close] = usePopup(),
    [selected, setSelected] = useContext(Picker.Context);

  return <>
    { React.cloneElement(React.Children.only(children), bindTrigger(popup)) }
    <Popover {...tapOnClose(bindPopover(popup), onClose)} {...POPOVER.CENTER}>
      <Box p={2} classes={{root: classes.box}} height={rows * 56 + 144}>
        <TabContext value={selected}>
          <TabList onChange={(_, s) => setSelected(s)} variant="scrollable">{
            groups.filter(g => g.subgroups.some(sg => sg[pick].length > 0))
              .map((g) =>
                <Tab
                  key={g.id}
                  value={g.id}
                  icon={<Icon item={g} size={64} />}
                  aria-label={g.name}
                  classes={{root: classes.tab}}
                />
              )
          }</TabList>
          { groups.map(g =>
            <TabPanel key={g.id} label={g.name} value={g.id}>{
              g.subgroups.map(sg =>
                <div key={sg.id}>{
                  sg[pick].map(p =>
                    <Tooltip key={p.id} title={p.name}>
                      <span>
                        <IconButton
                          disabled={disabled[p.id]}
                          onClick={tap(_ => onPick(p), onClose, close)}>
                          <Icon item={p} size={32} />
                        </IconButton>
                      </span>
                    </Tooltip>
                  )
                }</div>
              )
            }</TabPanel>
          )}
        </TabContext>
      </Box>
    </Popover>
  </>;
});
Picker.ITEMS = "items";
Picker.RECIPES = "recipes";
// TODO: wrap this up into a component?
Picker.state = game => game.groups[0].id;
Picker.Context = React.createContext([undefined, ()=>{}]);

const Edit = withStyles((theme) => ({
  icon: { minWidth: theme.spacing.px(3) },
  text: { marginLeft: theme.spacing.px(2) },
}))(({classes, onMoveUp, onMoveDown, onExpandAll, onCollapseAll, onDuplicate, onDelete, children}) => {
  const [popup, close] = usePopup();
  const actions = [
    ['Move Up', <ArrowUpwardIcon />, onMoveUp],
    ['Move Down', <ArrowDownwardIcon />, onMoveDown],
    ['Expand All', <UnfoldMoreIcon />, onExpandAll],
    ['Collapse All', <UnfoldLessIcon />, onCollapseAll],
    ['Duplicate', <FileCopyIcon />, onDuplicate],
    ['Delete', <DeleteIcon />, onDelete],
  ];

  if (actions.every(([, , f]) => !f)) { // no actions, no menu
    return <>{children}</>;
  }

  return <>
    { React.cloneElement(React.Children.only(children), bindTrigger(popup)) }
    <Menu {...bindMenu(popup)} {...MENU.LEFT}>{
      actions.filter(([,,onClick]) => !!onClick).map(([text, icon, onClick]) =>
        <MenuItem key={text} button={true} dense={true} onClick={tap(onClick, close)}>
          <ListItemIcon classes={{root: classes.icon}}>{icon}</ListItemIcon>
          <ListItemText classes={{root: classes.text}}>{text}</ListItemText>
        </MenuItem>
      )
    }</Menu>
  </>;
});

const CollapseButton = withStyles((theme) => ({
  button: {
    flexGrow: 1,
    margin: theme.spacing.px(0.5, 0),
    minWidth: theme.spacing.px(3),
    backgroundColor: theme.palette.action.selected,
  },
  text: {
    padding: theme.spacing.px(0.5)
  },
}))(({location, title, onCollapse=()=>{}, classes: {button, text, ...classes}}) => {
  return (
    <Tooltip title={title} placement="right" classes={classes}>
      <Button
        classes={{root: button, text}}
        onClick={tap(() => location.toggle(), onCollapse)}>
        <UnfoldLessIcon />
      </Button>
    </Tooltip>
  );
});

const Effect = ({effect}) => {
  switch (effect) {
    // TODO: I18N
    case 'speed': return 'Speed';
    case 'productivity': return 'Productivity';
    case 'pollution': return 'Pollution';
    case 'consumption': return 'Electricity';
    default: throw new Error(`Unknown Effect ${effect}`);
  }
};

const Effects = ({game, effects}) => {
  return (
    game.effects.filter(e => effects[e]).map(e =>
      <Box key={e} component="span" mr={1}>
        <Effect effect={e} />{': '}
        <Delta amount={effects[e]} />
      </Box>
    )
  );
};

const usePopup = () => {
  const [popupId] = useState(Math.random() + '');
  const popup = usePopupState({variant: 'popover', popupId});
  return [popup, () => popup.close()];
};

const useToggle = init => {
  invariant(init === true || init === false, 'Invalid Toggle Value: ' + init);
  const [state, setState] = useState(init);
  return [state, () => setState(!state)];
};

const bindDialog = (...args) => {
  const bind = bindPopover(...args);
  delete bind.anchorEl;
  return bind;
};

const tapOnClose =
  ({onClose, ...bind}, close) => ({ onClose: tap(onClose, close), ...bind });


export {
  NumericInput, Durations, Picker, Edit, CollapseButton, Effects,
  usePopup, useToggle,
  bindDialog, tapOnClose,
  POPOVER, MENU,
};
