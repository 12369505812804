// @flow

import { GAMES } from './Game';
import History from './History';
import Storage from './Storage';
import { Load } from './Actions.jsx';
// import * as serviceWorker from './serviceWorker';

window.onpopstate = e => Load[e.state.ns](e.state.id);

const [ns, id] = window.location.pathname.substr(1).split('/');
if (ns && id) {
  Load[ns](id);
} else if (Storage.get('id')) {
  Load[History.NS.SAVES](Storage.get('id'));
} else {
  Load[History.NS.GAMES](GAMES[0].games[0].id);
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
