// @flow

import React, { useContext } from 'react';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
// import ArrowBackIcon from '@material-ui/icons/ArrowBack'; // TODO: Mobile
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
// TODO: iOS-specific Icons?
// import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
// import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import CloseIcon from '@material-ui/icons/Close';
import { withStyles } from '@material-ui/core/styles';

import { empty, filter, topN } from './Bus';
import Factory from './Factory';
import { POLLUTION } from './Game';
import { Game } from './Context';
import { Icon } from './Basic';
import { Picker, Edit } from './Components';
import { Section as Balance } from './Balance';

const Section = ({section: {name, provides, balance, parts}, level, selected, location}) => {
  return (
    <Box width={384} m={3}>
      <Header {...{name, balance, level, location}} />
      <Balance
        {...balance}
        provides={provides}
        level={level}
        location={location.extend('provides')}
      />
      <Parts {...{parts, level, selected}} location={location.extend('parts')} />
    </Box>
  );
};

const Header = withStyles((theme) => ({
  root: {
    '&:hover $close': {
      opacity: 1,
    },
  },
  close: {
    marginTop: '8px',
    marginBottom: '7px',
    color: theme.palette.text.secondary,
    opacity: 0,
    transition: theme.transitions.create(['opacity']),
  },
}))(({balance: {products, byproducts}, name, level, location, classes: {root, close}}) => {
  const items = filter(
    empty(products) ? byproducts : products,
    id => id !== POLLUTION,
  );
  return (
    <Box display="flex" className={root}>
      <MultiIconButton disabled={true} items={items} />
      <Title {...{name, items, level}} location={location.extend('name')} />
      { level === 0
        ? null
        : <Tooltip title="Close">
            <IconButton
              size="small"
              onClick={() => { location.focus(1); }}
              classes={{root: close}}>
              <CloseIcon />
            </IconButton>
          </Tooltip>
      }
    </Box>
  );
});

const Title = withStyles((theme) => ({
  text: {
    marginLeft: theme.spacing.px(0.5),
  },
  root: {
    ...theme.typography.h2,
    paddingLeft: theme.spacing.px(1),
    alignSelf: "end",
  },
  input: {
    height: 'auto',
    '&::placeholder': {
      opacity: 1,
    },
    '&:focus::placeholder': {
      opacity: 0.5,
    },
    '&:disabled': {
      paddingBottom: '1px',
    },
  },
  underline: {
    '&:before': {
      borderBottomStyle: 'none',
      bottom: '1px',
    },
    '&:after': {
      bottom: '1px',
    },
    '&$disabled:before': {
      border: 'none',
    },
    '&:hover:not($disabled):before': {
      borderBottomColor: theme.palette.text.hint,
      transition: theme.transitions.create(
        'border-bottom-width',
        {
          duration: theme.transitions.duration.shortest,
          delay: 70,
          easing: theme.transitions.easing.easeOut,
        },
      ),
    },
  },
  disabled: {
    color: theme.palette.text.primary,
    cursor: 'pointer',
  },
}))(({name, items, level, location, classes: {text, ...classes}, ...fwd}) => (
  <TextField
    {...fwd}
    classes={{root: text}}
    fullWidth={true}
    placeholder={placeholder(useContext(Game), items, level)}
    defaultValue={name}
    InputProps={{classes}}
    onBlur={e => location.set(e.target.value || undefined)}
  />
));
Title.muiName = TextField.muiName;

const placeholder = (game, items, level) => {
  const { top, rest } = topN(items, 1); // TODO: multiple?
  return (
    top.length > 0
      ? top.map(([i]) => game.items[i].name).join(', ') // TODO: I18N
        + (rest.length > 0 ? ', ...' : '')
      : (level === 0 ? 'New Game' : 'New Section')
  );
};

const Parts = ({parts, level, selected, location}) => <>
  <Typography variant="h3">Sections and Recipes</Typography>
  { parts.map((part, ii) =>
      <Part
        key={part.id}
        part={part}
        level={level + 1}
        selected={selected === ii}
        location={location.extend(ii)}
        onMoveUp={ii > 0 && (() => location.extend(ii - 1).transpose())}
        onMoveDown={ii < parts.length - 1 && (() => location.extend(ii).transpose())}
        onDuplicate={() => location.extend(ii).duplicate(Factory.clone)}
        onDelete={() => {
          selected === ii && location.focus();
          location.extend(ii).remove();
        }}
      />
  )}
  <Add length={parts.length} location={location} />
</>;

const Part = withStyles(theme => ({
  recipe: {
    backgroundColor: theme.palette.action.selected,
    padding: theme.spacing.px(1),
  },
  button: {
    flexGrow: 1,
    marginLeft: theme.spacing.px(0.5),
    backgroundColor:
      ({selected}) => selected ? theme.palette.action.selected : 'transparent',
   '&:hover $arrow': {
     opacity: 1,
   }
  },
  label: {
    ...theme.typography.h4,
    textTransform: 'none',
    justifyContent: 'start',
  },
  arrow: {
    opacity: 0,
    transition: theme.transitions.create(['opacity']),
  },
}))(({part, level, selected, location, onMoveUp, onMoveDown, onDuplicate, onDelete, classes: {recipe, button, label, arrow}}) => {
  const game = useContext(Game);
  const items = filter(part.balance.products, id => id !== POLLUTION);
  const [icon, text] = part.recipe
    ? [ <IconButton classes={{root: recipe}}>
          <Icon item={part.recipe} size={24} />
        </IconButton>,
        part.recipe.name ]
    : [ <MultiIconButton items={items} />,
        part.name || placeholder(game, items, level) ];
  return (
    <Box mb={1} mt={1} display="flex">
      <Edit {...{onMoveUp, onMoveDown, onDuplicate, onDelete}}>{icon}</Edit>
      <Button
        selected={selected}
        classes={{root: button, label}}
        onClick={() => location.focus()}
        endIcon={<ArrowForwardIcon classes={{root: arrow}} />}>
        {text}
      </Button>
    </Box>
  );
});

const MultiIconButton = withStyles(theme => ({
  root: {
    backgroundColor: theme.palette.action.selected,
    minWidth: theme.spacing.px(5),
    height: '40px',
  },
  label: {
    width: '32px',
    height: '32px',
    display: 'inline-flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    alignItems: 'center',
  },
  text: {
    padding: theme.spacing.px(0.5),
  },
}))(React.forwardRef(({items, ...fwd}, ref) => {
  const game = useContext(Game);
  return (
    <Button {...fwd} ref={ref}>{
      topN(items, 4, ...game.sort.items).top.map(([k], _, t) =>
        <Icon key={k} item={game.items[k]} size={t.length > 1 ? 16 : 24} />)
    }</Button>
  );
}));
MultiIconButton.muiName = Button.muiName;

const Add = withStyles(theme => ({
  root: {
    '&:hover $buttons': {
      opacity: 1,
    },
  },
  icon: {
    fontSize: '40px',
    padding: theme.spacing.px(0.5),
    color: theme.palette.text.secondary,
  },
  buttons: {
    opacity: 0,
    transition: theme.transitions.create(['opacity']),
  }
}))(({location, length, classes: {root, icon, buttons}}) => {
  const beacon = Object.values(useContext(Game).beacons)[0];
  return (
    <Box display="flex" classes={{root}}>
      <AddCircleOutlineIcon className={icon} />
      <Box ml={0.5} clone={true}>
        <ButtonGroup variant="text" classes={{root: buttons}}>
          <Button onClick={() => {
              location.push(Factory.section());
              location.extend(length).focus();
            }}>
            Add Section
          </Button>
          <Picker
            pick="recipes"
            onPick={r => {
              location.push(Factory.block(r, beacon));
              location.extend(length).focus();
            }}>
            <Button>Add Recipe</Button>
          </Picker>
        </ButtonGroup>
      </Box>
    </Box>
  );
});


export default Section;
