// @flow

import React from 'react';
import ReactDOM from 'react-dom';

import id from './ID';
import App from './App';
import Game from './Game';
import Factory from './Factory';
import History from './History';
import Storage from './Storage';


const gid = id; // TODO: clean this up somehow

const Load = (function(){
  var loading, waiting;

  function enqueue(fn) {
    waiting = fn;
    check();
  }

  function check() {
    if (loading || !waiting) {
      return;
    }
    loading = waiting;
    waiting = null;
    loading().finally(() => { loading = null; check(); });
  }

  function render(factory, id) {
    if (window.__DEV__) {
      console.info('JSFC Running', id, factory);
      window.JSFC = {
        id: id,
        factory: factory,
        game: factory.game,
        forceGameID: gid => {
          factory.game.id = gid;
          Factory.share(factory).then(([sid]) => {
            console.log("Game ID Set.  View at http://jsfc-app.com/shares/" + sid);
          });
        },
      };
    }
    const store = ({id, factory}) => {
      // In order to avoid race conditions and synchronization issues, we always
      // try to save the factory data to the server.
      // Factory.store(id, factory); // No Local Storage for Now
      Factory.save(id, factory);
      Storage.set('id', id);
      document.title = (factory.section.name ? factory.section.name + ' | ' : '') + 'JSFC';
    };
    ReactDOM.render(
      <React.StrictMode>
        <App
          initialState={{id, factory}}
          onDurationChange={d => Storage.set('duration', d)}
          onFocusChange={f => History.hash(f.join(','))}
          onStateChange={store}
        />
      </React.StrictMode>,
      document.getElementById('root'),
    );
  }

  return {
    [History.NS.SHARES]:
      id => enqueue(() => Factory.load(id, 'shares').then(f => render(f, gid()))),
    [History.NS.SAVES]:
      id => enqueue(() => Factory.load(id, 'saves').then(f => render(f, id))),
    [History.NS.GAMES]:
      id => enqueue(() => Game.load(id).then(g => render(Factory.create(g), gid()))),
  };
})();

const Store = {
  [History.NS.SHARES]: f => Factory.share(f).then(([id]) => {
    History.set(History.NS.SHARES, id);
    return [id, f];
  }),
  [History.NS.SAVES]: (id, f) => Factory.save(id, f).then(() => {
    History.set(History.NS.SAVES, id);
    return [id, f];
  }),
  [History.NS.GAMES]: id => Game.load(id).then(g => {
    const id = gid();
    History.set(History.NS.SAVES, id);
    return { id, factory: Factory.create(g) };
  }),
};


export { Load, Store };
