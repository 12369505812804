// @flow

function epsilon(a, b) {
  return a === b || Math.abs(b - a) < 1e-9;
}

const bus = Object.fromEntries;
const lanes = Object.entries;

function width(bs) {
  return lanes(bs).length;
}

function empty(bs) {
  return width(bs) === 0;
}

function map(bs, f) {
  return bus(lanes(bs).map(([item, num]) => [item, f(item, num)]));
}

function scale(bs, factor) {
  return map(bs, (_, num) => factor * num);
}

function invert(bs) {
  return map(bs, (_, num) => 1 / num);
}

function merge(lhs, rhs) {
  var ret = {};
  for (const [item, num] of lanes(lhs)) {
    ret[item] = num;
  }
  for (const [item, num] of lanes(rhs)) {
    if (!ret[item]) {
      ret[item] = 0;
    }
    ret[item] += num;
  }
  for (const [item, num] of lanes(ret)) {
    if (epsilon(num, 0)) {
      ret[item] = 0;
    }
  }
  return ret;
}

function equal(lhs, rhs) {
  for (const [item, num] of lanes(lhs)) {
    if (!epsilon(rhs[item], num)) {
      return false;
    }
  }
  for (const [item, num] of lanes(rhs)) {
    if (!epsilon(lhs[item], num)) {
      return false;
    }
  }
  return true;
}

function filter(bs, f) {
  return bus(lanes(bs).filter(([item, num]) => f(item, num)));
}

function sort(bs, by = (_, num) => -num, ...rest) {
  rest.unshift(by);
  return lanes(bs).sort(([ai, an], [bi, bn]) =>
    rest.reduce((c, f) => c === 0 ? f(ai, an) - f(bi, bn) : c, 0));
}

function topN(bs, n, ...by) {
  const sorted = sort(bs, ...by);
  return { top: sorted.slice(0, n), rest: sorted.slice(n) };
}

export { epsilon, bus, lanes, width, empty, map, scale, invert, merge, equal, filter, sort, topN };
