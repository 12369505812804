// @flow

import React, { useState } from 'react';


import AppBar from '@material-ui/core/AppBar';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Divider from '@material-ui/core/Divider';
import Link from '@material-ui/core/Link';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Popover from '@material-ui/core/Popover';
import TextField from '@material-ui/core/TextField';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDownOutlined';
import CloudUploadIcon from '@material-ui/icons/CloudUploadOutlined';
import ShareIcon from '@material-ui/icons/ShareOutlined';
import { withStyles } from '@material-ui/core/styles';

import { bindTrigger, bindMenu, bindPopover } from 'material-ui-popup-state/hooks';

import { map, equal } from './Bus';
import History from './History';
import { Store } from './Actions.jsx';
import { GAMES } from './Game';
import { Icon } from './Basic';
import { Durations, NumericInput, Effects, usePopup, bindDialog, tapOnClose, POPOVER, MENU } from './Components';
import { Wordmark } from './Logo';


const Navigation = ({id, factory, research, location, setDuration}) => {
  const [disabled, setDisabled] = useState(false);
  const [, setError] = useState(undefined); // TODO: show errors

  function action(ns, ...args) {
    setDisabled(true);
    return Store[ns](...args)
      .catch(e => {
        setError(ns);
        console.error('Error with action `%s`: ', ns, e);
      })
      .finally(() => setDisabled(false));
  }

  function create(id) {
    return action(History.NS.GAMES, id).then(({id, factory}) => {
      location.extend('id').set(id);
      location.extend('factory').set(factory);
    });
  }

  return (
    <AppBar position="sticky" color="inherit">
      <Toolbar>
        <Logo />
        <ButtonGroup
          variant="text"
          style={{flexGrow: 1, justifyContent: 'center'}}>
          <New create={create} game={factory.game} />
          <Info game={factory.game} />
          <Research
            game={factory.game}
            research={research}
            location={location.extend('factory', 'research')} />
          <Factors
            factors={factory.factors}
            location={location.extend('factory', 'factors')} />
          <Duration setDuration={setDuration} />
        </ButtonGroup>
        <ButtonGroup size="small">
          <Button
            onClick={_ => action(History.NS.SAVES, id, factory)}
            variant="outlined"
            disabled={disabled}
            startIcon={<CloudUploadIcon/>}>
            Save
          </Button>
          <Button
            onClick={_ => action(History.NS.SHARES, factory)}
            variant="outlined"
            disabled={disabled}
            startIcon={<ShareIcon/>}>
            Share
          </Button>
        </ButtonGroup>
      </Toolbar>
    </AppBar>
  );
};

const Logo = fwd => {
  const [popup, close] = usePopup();
  return <Box mr={2}>
    <Button {...fwd} {...bindTrigger(popup)}>
      <Wordmark size={32} />
    </Button>
    <Menu {...bindMenu(popup)} {...MENU.LEFT}>
      <About onClose={close} />
      <Help onClose={close} />
    </Menu>
  </Box>;
};

const About = React.forwardRef(({onClose, ...fwd}, ref) => {
  const [popup] = usePopup();
  return <>
    <MenuItem {...bindTrigger(popup)} {...fwd} ref={ref}>About</MenuItem>
    <Dialog {...tapOnClose(bindDialog(popup), onClose)}>
      <DialogTitle><Wordmark size={64} /></DialogTitle>
      <DialogContent>
        <DialogContentText>
          JavaScript Factorio Calculator by{' '}
          <Link href="mailto:jeffreyspehar@gmail.com">Jeffrey Spehar</Link>
        </DialogContentText>
        <DialogContentText>
          Thanks to Wube Software and the{' '}
          <Link href="https://factorio.com/game/about" target="_blank">
          Factorio team</Link> for making one of the <Link
          href="https://factorio.com/" target="_blank">greatest games</Link> of
          all time.
        </DialogContentText>
        <DialogContentText>&copy; 2020 All Rights Reserved</DialogContentText>
      </DialogContent>
    </Dialog>
  </>;
});

const Help = ({onClose, ...fwd}) => {
  const [popup] = usePopup();
  return <>
    <MenuItem {...bindTrigger(popup)} {...fwd}>Help</MenuItem>
    <Dialog {...tapOnClose(bindDialog(popup), onClose)}>
      <DialogTitle>Help!</DialogTitle>
      <DialogContent>
        <DialogContentText>I need somebody.</DialogContentText>
      </DialogContent>
    </Dialog>
  </>;
};

const New = withStyles(theme => ({
  subheader: {
    padding: theme.spacing.px(0.5, 1),
    lineHeight: theme.typography.h4.lineHeight,
  },
}))(({create, game, classes: {subheader}, ...fwd}) => {
  const
    [popup, close] = usePopup(),
    current = { name: 'Current', games: [game] },
    onClick = id => e => { e.preventDefault(); close(); create(id); };
  return <>
    <Button
      {...fwd}
      {...bindTrigger(popup)}
      startIcon={<ArrowDropDownIcon/>}>
      New
    </Button>
    <Menu {...bindMenu(popup)} {...MENU.LEFT}>
      {[current, ...GAMES].map(({name: n, games}) => [
        <ListSubheader key={n} disableGutters={true} classes={{root: subheader}}>
          {n}
        </ListSubheader>,
        ...games.map(({id, name}) =>
          <Link key={n + id} color="inherit" underline="none" href={`/games/${id}`}>
            <MenuItem onClick={onClick(id)}>{name}</MenuItem>
          </Link>
        )
      ]).flat()}
    </Menu>
  </>;
});

const Info = ({game, ...fwd}) => {
  const
    [popup] = usePopup(),
    mods = Object.entries(game.mods).filter(([name]) => name !== "base");
  return <>
    <Button {...fwd} {...bindTrigger(popup)}>{game.name}</Button>
    <Popover {...bindPopover(popup)} {...POPOVER.CENTER}>
      <List dense={true}>
        <Mod base={true} name="Base" version={game.mods.base} />
        { mods.length > 0 ? <Divider /> : null }
        { mods.map(([name, version]) => <Mod key={name} {...{name, version}} />) }
      </List>
    </Popover>
  </>;
};

const Mod = withStyles(theme => ({
  root: {
    paddingTop: 0,
    paddingBottom: 0,
  },
}))(({name, version, base, ...fwd}) => {
  const item = base ? {} : {
    button: true,
    component: "a",
    href: "https://mods.factorio.com/mod/" + encodeURIComponent(name),
    target: "_blank",
  };
  return (
    <ListItem {...fwd} {...item}>
      <ListItemText primary={name} secondary={version} />
    </ListItem>
  );
});

const Research = withStyles(theme => ({
  cell: {
    display: 'table-cell',
    verticalAlign: 'middle',
    paddingRight: theme.spacing.px(1.5),
    paddingTop: theme.spacing.px(1),
  },
  icon: {
    verticalAlign: 'top',
  },
  field: {
    width: '3.75em',
  },
  numeric: {
    ...theme.typography.body1,
    paddingBottom: '3px',
  },
  input: {
    height: theme.typography.body1.lineHeight,
    paddingTop: '3px',
    paddingBottom: '1px',
  },
}))(({game, research, location, classes: {cell, bonus, icon, field, numeric, input}, ...fwd}) => {
  const [popup] = usePopup();
  return <>
    <Button {...fwd} {...bindTrigger(popup)}>Research</Button>
    <Popover {...bindPopover(popup)} {...POPOVER.CENTER}>
      <Box p={3} display="table">
        <Typography variant="h4" component="div">Research</Typography>
        { research.map(({id, level, max, effects, ...res}) =>
          <Box key={id} display="table-row">
            <span className={cell}>
              <Typography variant="h5" component="span">
                <Icon item={res} size={24} className={icon} />
                <Box ml={1} component="span">{res.name}</Box>
              </Typography>
            </span>
            <span className={cell}>
              <NumericInput
                min={0}
                max={max}
                step={1}
                value={level}
                onValueChange={v => location.extend(id).set(v || 0)}
                classes={{root: field}}
                InputProps={{classes: {root: numeric, input}}}
              />
            </span>
            <Bonus game={game} effects={effects} />
          </Box>
        )}
      </Box>
    </Popover>
  </>;
});

const Bonus = withStyles(theme => ({
  root: { // based on Research/cell
    display: 'table-cell',
    verticalAlign: 'middle',
    paddingRight: theme.spacing.px(1.5),
    paddingTop: theme.spacing.px(1),
    minWidth: theme.spacing.px(30),
  },
  icon: {
    verticalAlign: 'top',
    marginRight: theme.spacing.px(0.5),
  },
}))(({game, effects, classes: {root, icon}}) => {
  const index = [];
  map(effects, (c, es) => {
    const found = index.find(ix => equal(ix.effects, es));
    if (found) {
      found.crafters.push(c);
    } else {
      index.push({effects: es, crafters: [c]});
    }
  });

  return (
    <span className={root}>
      { index.map(({crafters, effects}, i) =>
        <Box key={JSON.stringify({crafters, effects})} display="block" component="span">
          { crafters.map(c =>
            <Icon key={c} item={game.crafters[c]} size={20} className={icon} />
          )}
          {' '}
          <Effects game={game} effects={map(effects, (_, v) => v + 1)} />
        </Box>
      )}
    </span>
  );
});

const Factors = withStyles(theme => ({
  cell: {
    display: 'table-cell',
    verticalAlign: 'middle',
    paddingRight: theme.spacing.px(1.5),
    paddingTop: theme.spacing.px(1),
  },
}))(({factors, location, classes: {cell, field, numeric, input}, ...fwd}) => {
  const [popup] = usePopup();
  return <>
    <Button {...fwd} {...bindTrigger(popup)}>Factors</Button>
    <Popover {...bindPopover(popup)} {...POPOVER.CENTER}>
      <Box p={3} display="table">
        { factors.map(({name, value}, ii) =>
          <Box key={ii} display="table-row">
            <span className={cell}>
              <TextField
                defaultValue={name}
                placeholder="New Factor"
                onBlur={e => location.extend(ii, 'name').set(e.target.value || '')} />
            </span>
            <span className={cell}>
              <NumericInput
                min={0}
                value={value}
                onValueChange={
                  v => location.extend(ii, 'value').set(v === 0 ? v : (v || 1.0))}
              />
            </span>
          </Box>
        )}
        <Button
          startIcon={<AddCircleOutlineIcon />}
          onClick={() => location.push({name: '', value: 1.0})}>
          Add Factor
        </Button>
      </Box>
    </Popover>
  </>;
});

const Duration = ({setDuration, ...fwd}) =>
  <Box component="span" position="relative" top="2px" ml={0.5}>
    <Durations onChange={setDuration} {...fwd} />
  </Box>;


export default Navigation;
