// @flow

import React, { useContext} from 'react';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import IconButton from '@material-ui/core/IconButton';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import Tooltip from '@material-ui/core/Tooltip';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import CloseIcon from '@material-ui/icons/Close';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import { withStyles } from '@material-ui/core/styles';

import { bindTrigger, bindPopover } from 'material-ui-popup-state/hooks';

import { Game } from './Context';
import { Block as Balance, Item } from './Balance';
import { Icon } from './Basic';
import { NumericInput, usePopup, POPOVER } from './Components';


// Represents a manufacturing block of machines crafting a single recipe
const Block = ({block: {recipe, crafter, beacon, balance}, location}) => (
  <Box width={384} m={3}>
    <Header recipe={recipe} location={location} />
    <Balance {...{...balance, crafter}} />
    <Controls {...{recipe, crafter, beacon, location}} />
    { balance.required[beacon.building.id] === undefined
      ? null
      : <Geometry {...{crafter, beacon}} location={location} />
    }
  </Box>
);

const Header = withStyles(theme => ({
  root: {
    '&:hover $close': {
      opacity: 1,
    },
  },
  icon: {
    padding: theme.spacing.px(1),
    '&$disabled': {
      backgroundColor: theme.palette.action.selected,
    },
  },
  disabled: {
    // empty rule is required to generate a class name that's used above
  },
  close: {
    marginTop: '5px',
    marginBottom: '5px',
    color: theme.palette.text.secondary,
    opacity: 0,
    transition: theme.transitions.create(['opacity']),
  },
}))(({recipe, location, classes: {root, icon, disabled, close}}) => {
  return (
    <Box display="flex" className={root}>
      <IconButton disabled={true} classes={{root: icon, disabled}}>
        <Icon item={recipe} size={24} />
      </IconButton>
      <Box pl={1} pt={0.5} width="100%">
        <Typography variant="h2">{recipe.name}</Typography>
      </Box>
      <IconButton
        size="small"
        classes={{root: close}}
        onClick={() => { location.focus(1); }}>
        <CloseIcon />
      </IconButton>
    </Box>
  );
});

const Controls = ({recipe, crafter, beacon, location}) => <>
  <Building
    label="Crafter"
    buildings={recipe.crafters}
    fuels={crafter.building.fuels}
    allowed={recipe.modules}
    building={crafter.building}
    fuel={crafter.fuel}
    modules={crafter.modules}
    location={location.extend('crafter')}
  />
  <Building
    label="Beacons"
    buildings={Object.values(useContext(Game).beacons)}
    fuels={beacon.building.fuels}
    allowed={recipe.modules}
    building={recipe.modules.length > 0 && beacon.building}
    fuel={beacon.fuel}
    modules={beacon.modules}
    location={location.extend('beacon')}>
    <Beacons crafter={crafter} beacon={beacon} location={location.extend('beacon')} />
  </Building>
</>;

const Building = ({label, buildings, fuels, allowed, building, fuel, modules, location, children}) => (
  !building
    ? []
    : <Box mb={1}>
        <Typography variant="h3">{label}</Typography>
        <Select
          options={buildings}
          value={building}
          location={location.extend('building')} />
        <Select
          options={fuels}
          value={fuel}
          location={location.extend('fuel')} />
        <Modules
          allowed={building.modules.filter(m => allowed.includes(m))}
          slots={building.slots}
          modules={modules}
          location={location.extend('modules')} />
        {children}
      </Box>
);

const Select = withStyles({
  root: { padding: '4px' },
})(({options, value, location, classes}) => (
  !options || options.length === 0
    ? []
    : <Box mr={1} clone={true}>
        <ToggleButtonGroup
          exclusive={true}
          value={value}
          onChange={(_, v) => v && location.set(v)}>{
            options.map(option =>
              <Tooltip key={option.id} title={option.name} value={option}>
                <ToggleButton classes={classes} disableRipple={value === option}>
                  <Icon item={option} size={26} />
                </ToggleButton>
              </Tooltip>
            )
        }</ToggleButtonGroup>
      </Box>
));

const Modules = withStyles({
  grouped: { minWidth: '36px' },
})(({allowed, slots, modules, location, classes}) =>
  allowed.length === 0
    ? []
    : <ButtonGroup classes={classes}>{
        [...Array(slots).keys()].map(i =>
         <ModuleButton
           key={i + (modules[i]?.id || '')}
           module={modules[i]}
           allowed={allowed}
           index={i}
           location={location}
         />
       )
     }</ButtonGroup>
);

// Using a hook (e.g., `usePopup`) inside a loop generates an error, so we
// create a new component for the module button and associated popover.
const ModuleButton = withStyles(theme => ({
  root: {
    padding: '4px',
  },
  icon: {
    fontSize: '16px', // results in a 14px image!
    verticalAlign: 'top',
    paddingTop: '1px',
    paddingBottom: '1px',
    paddingRight: '2px',
  },
  tooltip: {
    verticalAlign: 'top',
    margin: '1px 8px 1px 16px',
  },
}))(({module, allowed, index, location, classes: {icon, tooltip, ...classes}, ...fwd}) => {
  const
    [popup] = usePopup(),
    title = <><AddCircleOutlineIcon classes={{root: icon}} />Add Module</>;
  return <>
    <Tooltip title={module?.name || title} {...fwd}>
      <Button {...bindTrigger(popup)} classes={classes}>
        { module
          ? <Icon item={module} size={26} />
          : <Box component="span" display="inline-block" width={26} height={26} />
        }
      </Button>
    </Tooltip>
    <Popover {...bindPopover(popup)} {...POPOVER.CENTER}>
      <Box p={1}>
        <ToggleButtonGroup size="small" value={module} exclusive={true}>{
          allowed.map(m =>
            <Tooltip key={m.id} title={m.name} value={m}>
              <ToggleButton
                disableRipple={module === m}
                onClick={() => location.splice(index, 1, m)}>
                <Icon item={m} />
              </ToggleButton>
            </Tooltip>
          )
        }</ToggleButtonGroup>
        { module
          ? <Tooltip title="Remove Module">
              <IconButton
                size="small"
                classes={{root: tooltip}}
                onClick={() => location.splice(index, 1)}>
                <DeleteForeverIcon />
              </IconButton>
            </Tooltip>
          : []
        }
      </Box>
    </Popover>
  </>;
});
ModuleButton.muiName = Button.muiName;

const Beacons = withStyles({
  root: {
    height: '36px',
  },
})(({crafter, beacon, location, classes}) => {
  const max =
    crafter.building.id === 'rocket-silo' ? 20
    : crafter.building.id === 'oil-refinery' ? 16
    : 12; // TODO: use building size
  return (
    <Box component="span" ml={1}>
      <Tooltip title="Beacons in Range">
        <NumericInput
          variant="outlined"
          size="medium"
          InputProps={{classes}}
          min={0}
          max={max}
          step={1}
          value={beacon.replication}
          onValueChange={v => location.extend('replication').set(v)}
        />
      </Tooltip>
    </Box>
  );
});

const Geometry = withStyles(theme => ({
  cell: {
    display: 'table-cell',
    verticalAlign: 'middle',
    paddingRight: theme.spacing.px(1),
  },
  total: {
    display: 'table-cell',
    verticalAlign: 'middle',
    paddingRight: theme.spacing.px(1),
    paddingTop: theme.spacing.px(1.5),
  },
  field: {
    width: '3.75em',
  },
  rows: {
    width: '3.75em',
    marginTop: '-3px',
    marginLeft: '0.8em',
    marginRight: '0.8em',
  },
  numeric: {
    ...theme.typography.body2,
    paddingBottom: '3px',
  },
  input: {
    height: theme.typography.body2.lineHeight,
    paddingTop: '3px',
    paddingBottom: '1px',
  },
}))(({crafter, beacon, location, classes, ...fwd}) => {
  const
    strings = (p, v) => ({
      machines: ['Machine', 'Machines'],
      rows: ['Row', 'Rows'],
      columns: ['Column', 'Columns'],
      blocks: ['Block', 'Blocks'],
    }[p][v === 1 ? 0 : 1]),
  {field, rows, numeric, input, cell, total} = classes;

  return (
    <Box mt={1}>
      <Typography variant="h3">Beacon Geometry</Typography>
      <Box mt={2} mb={1.5}>
        <Tooltip title={crafter.building.name}>
          <Item item={crafter.building} amount={crafter.required} fixed={0} />
        </Tooltip>
        in
        <NumericInput
          min={1}
          max={crafter.required}
          value={crafter.geometry.rows}
          onValueChange={
            v => location.extend('crafter', 'geometry', 'rows').set(v)}
          disabled={crafter.required <= 1}
          classes={{root: rows}}
          InputProps={{classes:{root: numeric, input}}}
        />
        {strings('rows', crafter.geometry.rows)}
      </Box>
      <Box display="table">
        {['machines', 'rows', 'columns', 'blocks'].map((prop, i, ps) =>
          <Box display="table-row" key={prop}>
            <span className={cell}>
              {i === 0 ? '' : '\u002B'}
            </span>
            <span className={cell}>
              <NumericInput
                min={
                  ps.filter(p => p !== prop).some(p => beacon.geometry[p])
                    ? 0
                    : (i === 0 ? 0.5 : 1)
                }
                step={i === 0 ? 0.5 : 1}
                value={beacon.geometry[prop]}
                onValueChange={
                  v => location.extend('beacon', 'geometry', prop).set(v || 0)}
                classes={{root: field}}
                InputProps={{classes:{root: numeric, input}}}
              />
            </span>
            <span className={cell}>Per {strings(prop, 1)}{' '}</span>
            <span className={cell}>{'\u00D7'}</span>
            <span className={cell}>
              {crafter.geometry[prop]}{' '}{strings(prop, crafter.geometry[prop])}
            </span>
          </Box>
        )}
        <Box display="table-row">
          <span className={total}>{'\u003D'}</span>
          <span className={total}>
            <Tooltip title={beacon.building.name}>
              <Item item={beacon.building} amount={beacon.required} fixed={0} />
            </Tooltip>
          </span>
          <span className={total}>Required</span>
          <span className={total} />
          <span className={total} />
        </Box>
      </Box>
    </Box>
  );
});

export default Block;
