// @flow

import React from 'react';

import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider, createMuiTheme } from '@material-ui/core/styles';

/* TODO: add these somehow?
::-webkit-scrollbar {
    background-color: #8A9BA8;
}

::-webkit-scrollbar-thumb {
    background-color: #A66321;
}
*/

const palette = {
  type: 'dark',
  background: { default: '#404040', paper: '#303030' },
  primary: { main: '#FCA000' }, // Orange
  secondary: { main: '#5EB663' }, // Green
  text: { primary: '#E6E6E6' }, // 90% white
  // Somehow both hover and hoverOpacity are required for MuiButton and
  // MuiIconButton to end up with rgba(230, 230, 230, 0.3)???
  action: { hover: '#E6E6E6', hoverOpacity: 0.3 },
};

const dark = createMuiTheme({palette});

// Titillium Web available weights: 200, 300, 400, 600, 700, 900
const [fontWeightLight, fontWeightRegular, fontWeightHeavy, fontWeightBold]
  = [300, 400, 600, 700];

// This only works with specifically formatted values (i.e., #RRGGBB)
const addOpacity = (rgb, a) => rgb + Math.round(a * 255).toString(16);

const theme = createMuiTheme({
  palette,

  typography: {
    fontFamily: '"Titillium Web", "DejaVu Sans", "Noto Sans", '
      + dark.typography.fontFamily,

    fontWeightLight,
    fontWeightRegular,
    fontWeightHeavy,
    fontWeightBold,

    h1: {
      color: '#FFE6C0',
      fontSize: dark.typography.pxToRem(30),
      fontWeight: fontWeightLight,
      letterSpacing: '0.019em',
      lineHeight: dark.typography.pxToRem(32),
      marginBottom: '4px',
    },
    h2: {
      fontSize: dark.typography.pxToRem(26),
      fontWeight: fontWeightLight,
      letterSpacing: '0.010em',
      lineHeight: dark.typography.pxToRem(28),
      marginBottom: '4px',
    },
    h3: {
      fontSize: dark.typography.pxToRem(22),
      fontWeight: fontWeightRegular,
      letterSpacing: '0.019em',
      lineHeight: dark.typography.pxToRem(24),
      marginBottom: '4px',
      marginTop: '8px',
    },
    h4: {
      fontSize: dark.typography.pxToRem(20),
      fontWeight: fontWeightRegular,
      letterSpacing: '-0.005em',
      lineHeight: dark.typography.pxToRem(24),
    },
    h5: {
      fontSize: dark.typography.pxToRem(16),
      fontWeight: fontWeightHeavy,
      letterSpacing: '0',
      lineHeight: dark.typography.pxToRem(20),
    },
    h6: {
      fontSize: dark.typography.pxToRem(14),
      fontWeight: fontWeightBold,
      lineHeight: dark.typography.pxToRem(16),
    },
    subtitle1: {
      fontSize: dark.typography.pxToRem(16),
      fontWeight: fontWeightLight,
      lineHeight: dark.typography.pxToRem(20),
    },
    subtitle2: {
      fontSize: dark.typography.pxToRem(14),
      fontWeight: fontWeightRegular,
      lineHeight: dark.typography.pxToRem(16),
    },
    body1: {
      fontSize: dark.typography.pxToRem(16),
      fontWeight: fontWeightRegular,
      letterSpacing: 0,
      lineHeight: dark.typography.pxToRem(20),
    },
    body2: {
      fontSize: dark.typography.pxToRem(14),
      fontWeight: fontWeightRegular,
      letterSpacing: 0,
      lineHeight: dark.typography.pxToRem(16),
    },
    button: {
      fontSize: dark.typography.pxToRem(16),
      fontWeight: fontWeightRegular,
      letterSpacing: '0.003em',
      lineHeight: dark.typography.pxToRem(24),
    },
    caption: {
      fontSize: dark.typography.pxToRem(12),
      fontWeight: fontWeightHeavy,
      letterSpacing: '0.004em',
      lineHeight: dark.typography.pxToRem(16),
    },
  },

  overrides: {
    MuiToggleButton: {
      root: {
        '&.Mui-selected': {
          backgroundColor: dark.palette.action.selected, // Seems like a bug
          // Ideally ToggleButtonGroup would have an `exclusive` class so we
          // could target this specifically (or make it part of the default
          // behavior!), but fortunately all JSFC uses are exclusive.
          cursor: 'default', // revert `cursor: 'pointer'` - button default
        },
        '&:hover': {
          backgroundColor: addOpacity( // also seems like a bug
            dark.palette.action.hover, dark.palette.action.hoverOpacity),
        },
      },
    },
    MuiIconButton: {
      root: {
        '&:hover': {
          // why don't these components use the theme :(
          backgroundColor: addOpacity(
            dark.palette.action.hover, dark.palette.action.hoverOpacity),
        },
      },
    },
    MuiOutlinedInput: {
      root: {
        '&:hover:not(.Mui-focused) $notchedOutline': {
          borderColor: dark.palette.text.hint,
        },
      },
      input: {
        padding: '7px 8px 6px',
        '&[type=number]::-webkit-inner-spin-button': { cursor: 'pointer' },
      },
    },
    MuiTooltip: {
      tooltip: {
        // Observed to be grey[700] @ 90% opacity; set to 100% opacity
        backgroundColor: dark.palette.grey[700],
        padding: `${dark.spacing(1)}px`,
        // Below options are the same as 'caption' typography variant
        fontSize: dark.typography.pxToRem(12),
        fontWeight: fontWeightHeavy,
        letterSpacing: '0.004em',
        lineHeight: dark.typography.pxToRem(16),
      },
      arrow: {
        color: dark.palette.grey[700], // same as tooltip.backgroundColor
      },
      tooltipPlacementTop: {
        [dark.breakpoints.up('sm')]: {
          // TODO: This is the observed value in the component styles; is this
          // calculated via some theme/spacing function?
          // From: .MuiTooltip-popperArrow[x-placement*="right"] .MuiTooltip-arrow
          marginBottom: '0.71em',
        },
      },
      tooltipPlacementBottom: {
        [dark.breakpoints.up('sm')]: {
          marginTop: '0.71em',
        },
      },
      tooltipPlacementLeft: {
        [dark.breakpoints.up('sm')]: {
          marginRight: '0.71em',
        },
      },
      tooltipPlacementRight: {
        [dark.breakpoints.up('sm')]: {
          marginLeft: '0.71em',
        },
      },
    },
    MuiLink: {
      // TODO: is this a stable class name?  This is technically a style which
      // crosses two stylesheets (MuiLink and MuiTypography) and I wonder if
      // it's safe against the CSS isolation mechanisms in a production build.
      // I guess a customized styled component might technically be the correct
      // thing to do?
      root: { '&.MuiTypography-colorPrimary': { color: '#FFE6C0' } }, // light yellow
      button: { verticalAlign: 'baseline' }, // this feels like a bug fix
    },
    MuiDialogTitle: {
      root: {
        paddingTop: `${dark.spacing(3)}px`,
        paddingBottom: `${dark.spacing(1)}px`,
      },
    },
    MuiListItem: {
      button: {
        '&:hover': {
          // palette.actions.hover is too light for palette.background.paper so
          // components must use a custom background-color value in these cases
          backgroundColor: addOpacity('#ADADAD', dark.palette.action.hoverOpacity),
        }
      },
    },
    MuiListSubheader: {
      root: { textTransform: 'uppercase' },
    },
  },

  props: {
    MuiTooltip: { arrow: true, placement: "top" },
  },
});

theme.spacing.px = (a, ...r) =>
  r.length > 0 ? theme.spacing(a, ...r) : (`${theme.spacing(a)}px`);
theme.palette.addOpacity = addOpacity;

const Theme = ({children}) => {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      { children }
    </ThemeProvider>
  );
};


export default Theme;
