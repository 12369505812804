// @flow

import id from './ID';
import migrate from './Migrate';
import Game from './Game';
import S3 from './S3';
import Storage from './Storage';

const VERSION = "0.3";

let pid = 0;

function clone(part) {
  const
    block = () => ({
      id: pid++,
      recipe: part.recipe,
      crafter: {
        building: part.crafter.building,
        modules: [...part.crafter.modules],
        geometry: {...part.crafter.geometry},
        fuel: part.crafter.fuel,
      },
      beacon: {
        building: part.beacon.building,
        modules: [...part.beacon.modules],
        replication: part.beacon.replication,
        geometry: {...part.beacon.geometry},
        fuel: part.beacon.fuel,
      },
    }),
    section = () => ({
      id: pid++,
      name: part.name,
      provides: {...part.provides},
      parts: part.parts.map(clone),
    });
  return part.recipe ? block() : section();
}

function dereference(part) {
  const
    block = () => ({
      recipe: part.recipe.id,
      crafter: {
        building: part.crafter.building.id,
        modules: part.crafter.modules.map(m => m.id),
        geometry: part.crafter.geometry,
        fuel: part.crafter.fuel?.id,
      },
      beacon: {
        building: part.beacon.building?.id,
        modules: part.beacon.modules.map(m => m.id),
        replication: part.beacon.replication,
        geometry: part.beacon.geometry,
        fuel: part.beacon.fuel?.id,
      },
    }),
    section = () => ({
      name: part.name,
      provides: part.provides,
      parts: part.parts.map(dereference),
    });
  return part.recipe ? block() : section();
}

function reference(game, part) {
  const
    block = () => ({
      id: pid++,
      recipe: game.recipes[part.recipe],
      crafter: {
        building: game.crafters[part.crafter.building],
        modules: part.crafter.modules.map(id => game.modules[id]),
        geometry: part.crafter.geometry,
        fuel: game.fuels[part.crafter.fuel],
      },
      beacon: {
        building: game.beacons[part.beacon.building],
        modules: part.beacon.modules.map(id => game.modules[id]),
        replication: part.beacon.replication,
        geometry: part.beacon.geometry,
        fuel: game.fuels[part.beacon.fuel],
      },
    }),
    section = () => ({
      id: pid++,
      name: part.name,
      provides: {...part.provides},
      parts: part.parts.map(p => reference(game, p)),
    });
  return part.recipe ? block() : section();
}

function block(recipe, beacon, fuel) { // TODO: `fuel` is unused
  // There's a bit of an odd implicit dependency here where the recipe and
  // beacon have to be passed in, and it's assumed the result of this call is
  // getting added into a factory attached to the corresponding game.  This is
  // OK for now, but it might be worth thinking about whether the game object
  // itself should somehow store/manage/mediate this dependency.  Logic to
  // manipulate the factory is spread out over many places, which makes factory
  // data/format updates difficult to make (especially in a non-breaking way
  // given that the factory is persisted).
  return {
    id: pid++,
    recipe,
    crafter: {
      building: recipe.crafters[0],
      modules: [],
      geometry: { rows: 1 },
    },
    beacon: {
      building: beacon,
      modules: [],
      replication: 0,
      geometry: { machines: 1, rows: 0, columns: 0, blocks: 0 },
    },
  };
}

function section() {
  return { id: pid++, provides: {}, parts: [] };
}

function research(game) {
  return Object.fromEntries(game.technologies.map(({id}) => [id, 0]));
}

const wrap = ({version, game: {id}, research, factors, section}) =>
  ({ version, game: id, research, factors, section: dereference(section) });

const create = (game, res, fac, sec) => (
  {
    version: VERSION,
    game,
    research: res || research(game),
    factors: fac || [],
    section: sec || section(),
  }
);

const key = id => 'factory_' + id;

export default {
  block,
  section,
  clone,

  create,
  load: (id, bucket) => {
    const s = Storage.get(key(id));
    return (s ? Promise.resolve(JSON.parse(s)) : S3.get(bucket, id))
//      .then(x => { console.log("Loaded", x); return x; })
      .then(migrate)
//      .then(x => { console.log("Migrated", x); return x; })
      .then(({game, research, factors, section}) =>
        Game.load(game).then(
          g => create(g, research, factors, reference(g, section))));
  },

  store: (id, f) => Storage.set(key(id), JSON.stringify(wrap(f))),
  save: (id, factory) => {
    const K = key(id), restore = Storage.get(K);
    Storage.remove(K);
    return S3.put('saves', id, wrap(factory)).then(r => [id, r]).catch(error => {
      if (!Storage.get(K)) {
        Storage.set(K, restore);
      }
      throw error;
    });
  },
  share: factory => {
    const sid = id();
    return S3.put('shares', sid, wrap(factory)).then(r => [sid, r]);
  },

};
