// @flow

import React from 'react';

import Box from '@material-ui/core/Box';
import useDimensions from 'use-element-dimensions';

import Section from './Section';
import Block from './Block';

const zip = (...arrays) => [
  ...Array(arrays.map(a => a.length).reduce((m, l) => Math.max(m, l), 0)).keys()
].map(ii => arrays.map(as => as[ii]));

const Columns = ({section, focus, location}) => {
  const [{ width }, ref] = useDimensions();
  return (
    <Box display="flex" ref={ref}>{
        zip(['section', ...focus], focus).reduce(
          ({parts, location, columns}, [ii, selected], level) =>
            parts[ii] === undefined
            ? ({ parts: [], columns: columns })
            : ({
              parts: parts[ii].parts,
              location: location.extend(ii, 'parts'),
              columns: [
                ...columns,
                parts[ii].recipe
                  ? <Block
                        key={parts[ii].id}
                        block={parts[ii]}
                        location={location.extend(ii)}
                        />
                  : <Section
                        key={parts[ii].id}
                        section={parts[ii]}
                        selected={selected}
                        location={location.extend(ii)}
                        level={level}
                        />,
              ],
            }),
          { parts: {section},
            location: location.extend('factory'),
            columns: [],
          }
        ).columns.slice(-Math.max(1, Math.floor((width - 24) / 392)))
    }</Box>
  );
};

export default Columns;
